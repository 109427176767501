<!--统计概况-->
<template>
    <div class="Overview">
        <a-row type="flex" :gutter="16">
            <a-col :span="12" style="height:100%">
                <a-row :gutter="20">
                    <a-col :span="8">
                        <a-card :bordered="false" style="border-radius: 16px;">
                            <a-row type="flex" justify="space-between" align="middle">
                                <span class="title">学生注册数</span>
                                <img src="@/assets/zhuce.png" />
                            </a-row>
                            <a-row type="flex" class="left_top_Main">
                                <div class="count">{{userVideoForm.student_count}}</div>
                            </a-row>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card :bordered="false" style="border-radius: 16px;">
                            <a-row type="flex" justify="space-between" align="middle">
                                <span class="title">视频总数</span>
                                <img src="@/assets/shipinshu.png" />
                            </a-row>
                            <a-row type="flex" class="left_top_Main">
                                <div class="count">{{userVideoForm.video_count}}</div>
                            </a-row>
                        </a-card>
                    </a-col>
                    <a-col :span="8">
                        <a-card :bordered="false" style="border-radius: 16px;">
                            <a-row type="flex" justify="space-between" align="middle">
                                <span class="title">视频播放量</span>
                                <img src="@/assets/shipinshu.png" />
                            </a-row>
                            <a-row type="flex" class="left_top_Main">
                                <div class="count">{{userVideoForm.video_view_count}}</div>
                            </a-row>
                        </a-card>
                    </a-col>
                </a-row>
                <a-row class="studentBox">
                    <a-card :bordered="false" class="studentCard">
                        <a-row type="flex" align="middle" class="studentRow">
                            <img src="@/assets/xuezhu.png" />
                            <span class="title">学生注册动态</span>
                            <div class="timeSearch" style="margin-left:auto">
                                <span :class="{'activeTime':currentTime == 7}" @click="currentTime = 7;studentData()">最近7天</span>
                                <span :class="{'activeTime':currentTime == 15}" @click="currentTime = 15;studentData()">最近15天</span>
                                <span :class="{'activeTime':currentTime == 30}" @click="currentTime = 30;studentData()">最近30天</span>
                            </div>
                        </a-row>
                        <div ref="chartRef" style="width: 100%;height: 400px;"></div>
                    </a-card>
                </a-row>
            </a-col>

            <a-col :span="12">
                <a-card :bordered="false" class="overVideoBox">
                    <a-row type="flex" align="middle" class="titleBox">
                        <img src="@/assets/shipaihang.png" />
                        <span class="title">视频排行</span>
                    </a-row>
                    <a-table :dataSource="RankForm" :columns="columns" rowKey="id" :pagination="false" :scroll="{x: true, y: 'calc(100vh - 433px)' }">
                        <template #number="{text, record, index}">
                            <span>{{index + 1 }}</span>
                        </template>
                    </a-table>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import * as echarts from 'echarts';
export default defineComponent({
    name: "OverviewManagment",
    setup() {
        const { proxy } = getCurrentInstance();
        // 获取echarts的dom元素
        const chartRef = ref();
        onMounted(() => {
            userVideo();
            studentData();
            videoRank();
            // 自适应大小
            window.onresize = function () {
                const myChart = echarts.init(chartRef.value);
                myChart.resize();
            };
        });
        const initData = reactive({
            userVideoForm: {},
            studentForm: {},
            RankForm: [],
            columns: [
                { title: '排序', dataIndex: 'number', width: 80, slots: { customRender: 'number' } },
                { title: '视频标题', dataIndex: 'name',width:250},
                { title: '视频类型', dataIndex: 'cate.name',width:100 },
                { title: '观看量', dataIndex: 'view_count_sum',width:100 },
                { title: '点赞量', dataIndex: 'like_count_sum',width:100 },
            ],
            currentTime: 7,
        });
        //学生视频统计
        const userVideo = () => {
            proxy.http.post('/admin.tongJi/countNow').then(response => {
                initData.userVideoForm = response;
            })
        }
        //视频排行
        const videoRank = () => {
            proxy.http.post('/admin.video/top').then(response => {
                initData.RankForm = response;
            })
        }
        //学生人数
        const studentData = () => {
            proxy.http.post('/admin.student/dayCount', { day: initData.currentTime }).then(response => {
                initData.studentForm = response;
                getData();
            })
        }

        const getData = () => {
            const barCharts = echarts.init(chartRef.value);
            barCharts.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                },
                grid: {
                    left: '3%',
                    right: '7%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: 'category',
                        name: '日期',
                        data: initData.studentForm.day,
                        axisTick: { show: false },// 关掉x轴中间的分割线
                        axisLine: {
                            lineStyle: {
                                width: 0,
                                color: '#999999',
                            },//x轴线的颜色
                        },
                        axisLabel: {
                            // x轴上的文字颜色
                            textStyle: {
                                color: '#666666',
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '人数',
                        axisLabel: {
                            color: '#666666',// y轴字体颜色
                        },
                    },
                ],
                series: [{
                    type: 'bar',
                    barWidth: '45%',
                    label: {
                        show: true,
                        position: 'top'
                    },
                    itemStyle: {
                        color: '#008685'// 柱形图的颜色和外观设置
                    },
                    data: initData.studentForm.count,
                }],
            });
        };
        return {
            ...toRefs(initData),
            userVideo,
            studentData,
            videoRank,
            getData,
            chartRef
        };
    },
});
</script>
<style lang="less" scoped>
:deep(.ant-card-body) {
    padding: 20px 15px !important;
}
</style>
